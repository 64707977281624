<template>
    <section>
        <div class="d-flex mx-2 pt-2">
            <div class="px-0 bg-white br-t-8" style="width:445px;">
                <div class="row mx-0 border-bottom align-items-center py-2">
                    <div class="col pr-0 pl-2">
                        <el-input v-model="buscar_cupon" size="small" placeholder="Buscar Carpeta" class="br-20 w-100" />
                    </div>
                    <div class="col-auto">
                        <el-tooltip v-if="$can('boton_carpeta_crear')" placement="bottom" content="Crear Carpeta de cupones" effect="light">
                            <div class="btn-red text-white" @click="crearCarpeta()">
                                <i class="icon-folder-multiple-plus f-18" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <div class="select-cupon custom-scroll overflow-auto">
                    <div v-for="(c, index) in carpetas.filter(data => !buscar_cupon || data.nombre.toLowerCase().includes(buscar_cupon.toLowerCase()))" :key="index" class="d-middle border-bottom px-2 py-2 cr-pointer" @click="seleccionarCarpeta(c)">
                        <div class="br-10" :class="idCarpetaActiva == c.id ? 'bg-general' : ''" style="height:40px;width:8px;" />
                        <div class="col text-general tres-puntos f-15 pl-1">
                            {{ c.nombre }}
                        </div>
                        <div v-show="!c.estado" class="col-auto px-0">
                            <i class="icon-lock f-22 text-general" />
                        </div>
                        <div class="col-auto px-1">
                            <el-tooltip placement="bottom" content="Cupones en esta carpeta" effect="light">
                                <div class="bg-general3 px-1 br-10 text-white">
                                    <i class="icon-cupon mr-2 f-15" />
                                    {{ c.cant_cupones }}
                                </div>
                            </el-tooltip>
                        </div>
                        <i class="icon-angle-right f-22" :class="idCarpetaActiva == c.id ? 'text-general' : 'text-gris'" />
                    </div>
                </div>
            </div>
            <div v-if="idCarpetaActiva" class="ml-3 px-0 cont-cupon bg-white br-t-8" style="width:calc(100vw - 536px)">
                <div class="d-flex mx-0 py-2 pr-2 border-bottom aling-items-center" style="height:49px">
                    <div class="col-auto text-general d-middle f-17">
                        {{ carpetaSeleccionada.nombre }}
                    </div>
                    <div class="col-auto d-middle ml-auto">
                        <span class="text-general mx-2">
                            Carpeta {{ carpetaSeleccionada.estado ? 'Activa' : 'Inactiva' }}
                        </span>
                        <el-switch
                        v-model="carpetaSeleccionada.estado"
                        :active-value="1"
                        :inactive-value="0"
                        :disabled="!$can('boton_carpeta_cambiar_estado')"
                        active-color="#000000"
                        @change="cambiarEstadoCarpeta"
                        />
                    </div>
                    <!-- <div class="col-auto d-middle px-0">
                    </div> -->
                    <div v-if="$can('boton_carpeta_crear')" class="btn-action border mr-2" @click="crearCarpeta(false)">
                        <el-tooltip placement="bottom" content="Cambiar nombre" effect="light">
                            <i class="icon-pencil-outline f-20" />
                        </el-tooltip>
                    </div>
                    <div v-show="carpetaSeleccionada.cant_cupones === 0 && $can('boton_carpeta_crear')" class="btn-action border mr-1">
                        <el-tooltip placement="bottom" content="Eliminar Carpeta" effect="light">
                            <i class="icon-trash-empty f-22" @click="eliminarCarpeta" />
                        </el-tooltip>
                    </div>
                    <div v-if="$can('boton_cupones_crear')" class="btn-red text-white" @click="$router.push({name: 'admin.cupones.crear'})">
                        <el-tooltip placement="bottom" content="Crear Cupón" effect="light">
                            <i class="icon-plus f-18" />
                        </el-tooltip>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0 custom-scroll overflow-auto" style="height:calc(100vh - 192px)">
                        <el-table :data="cuponesVigentes" header-row-class-name="text-general f-17" class="cr-pointer" style="width: 100%" @row-click="irDetalle">
                            <el-table-column class-name="text-center" width="50">
                                <template slot-scope="{row}">
                                    <el-tooltip placement="bottom" content="Cupón inactivo" effect="light">
                                        <i v-show="row.estado === 0" class="icon-lock f-20 text-general" />
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                            <el-table-column prop="nombre" label="Cupón vigente" min-width="200" />
                            <el-table-column label="Tipo" class-name="text-center" min-width="150">
                                <template slot-scope="{row}">
                                    <p> {{ funTipoCupon(row.tipo) }}</p>
                                </template>
                            </el-table-column>
                            <el-table-column prop="pais" label="País" min-width="150" />
                            <el-table-column label="Cedis" class-name="text-center" min-width="80">
                                <template slot-scope="{row}">
                                    <div class="row mx-0 justify-center">
                                        <div class="col-auto">
                                            <div class="bg-general3 br-10 text-white px-2 py-1">
                                                {{ row.cant_cedis }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Usados" class-name="text-center" min-width="150">
                                <template slot-scope="{row}">
                                    <div class="row mx-0 justify-center">
                                        <div class="col-auto">
                                            <p v-if="row.tipo === 1">{{ row.cant_usada }}</p>
                                            <p v-if="row.tipo === 2">{{ row.cant_usada }}({{ row.cant_usada_bono }})</p>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="Disponibles" class-name="text-center" min-width="150">
                                <template slot-scope="{row}">
                                    <div class="row mx-0 justify-center">
                                        <div class="col-auto">
                                            <p v-if="row.limite_total === null">Sin Limite</p>
                                            <p v-if="row.limite_total !== null">{{ row.cant_usada }}/{{ row.limite_total }}</p>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- Cupones vencidos -->
                        <div class="row mx-0 py-4" />
                        <div v-show="cuponesVencidos.length" class="row mx-0 mt-4">
                            <div class="col-12 px-0">
                                <el-table :data="cuponesVencidos" header-row-class-name="text-general f-17" class="cr-pointer" style="width: 100%" @row-click="irDetalle">
                                    <el-table-column class-name="text-center" width="50">
                                        <template slot-scope="{row}">
                                            <i v-show="row.estado === 0" class="icon-lock f-20 text-general" />
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="nombre" label="Cupón vencido" min-width="200" />
                                    <el-table-column label="Tipo" class-name="text-center" min-width="150">
                                        <template slot-scope="{row}">
                                            <p> {{ funTipoCupon(row.tipo) }}</p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="pais" label="País" min-width="150" />
                                    <el-table-column label="Cedis" class-name="text-center" min-width="80">
                                        <template slot-scope="{row}">
                                            <div class="row mx-0 justify-center">
                                                <div class="col-auto">
                                                    <div class="bg-general3 br-10 text-white px-2 py-1">
                                                        {{ row.cant_cedis }}
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Usados" class-name="text-center" min-width="150">
                                        <template slot-scope="{row}">
                                            <div class="row mx-0 justify-center">
                                                <div class="col-auto">
                                                    <p v-if="row.tipo === 1">{{ row.cant_usada }}</p>
                                                    <p v-if="row.tipo === 2">{{ row.cant_usada }}({{ row.cant_usada_bono }})</p>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Disponibles" class-name="text-center" min-width="150">
                                        <template slot-scope="{row}">
                                            <div class="row mx-0 justify-center">
                                                <div class="col-auto">
                                                    <p v-if="row.limite_total === null">Sin Limite</p>
                                                    <p v-if="row.limite_total !== null">{{ row.limite_total }}</p>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 cr-pointer" @click="$router.push({name: 'admin.cupones.ver'})"> -->
            </div>
            <div v-show="idCarpetaActiva === null" class="col ml-3 custom-scroll overflow-auto px-0 cont-cupon">
                <div class="text-center f-15 f-600 d-middle-center flex-column mx-auto h-100" style="width:225px;">
                    <div class="d-middle-center bg-whitesmoke br-5" style="width:170px;height:170;">
                        <img height="120" src="/img/no-imagen/click.svg" alt="" />
                    </div>
                    <p>Seleccione una de las carpetas activas del panel de la izquierda.</p>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-crear-carpeta ref="modalCrearCarpeta" @update="listarCarpetas" @edit="updateCarpeta" />
    </section>
</template>

<script>
import Cupones from '~/services/cupones/cupones'
export default {
    components: {
        modalCrearCarpeta: () => import('./partials/modalCrearCarpeta')
    },
    data(){
        return {
            buscar_cupon: '',
            active_cupon: null,
            cuponActivo: false,
            carpetas:[],
            idCarpetaActiva:null,
            carpetaSeleccionada:{},
            cuponesVencidos:[],
            cuponesVigentes:[],


        }
    },
    computed:{
        tipoVista(){
            if(this.$route.name === 'admin.cupones.clientes.lista') return 1
            if(this.$route.name === 'admin.cupones.lechero.lista') return 2
            return 0
        }
    },
    mounted(){
        this.listarCarpetas()
    },
    methods: {
        async listarCarpetas(){
            try {
                const id_cedis = 0
                const {data} = await Cupones.listarCarpetas(id_cedis, this.tipoVista)
                this.carpetas = data.carpetas

            } catch (e){
                this.error_catch(e);
            }
        },
        async seleccionarCarpeta(carpeta){
            this.idCarpetaActiva = carpeta.id
            this.carpetaSeleccionada = carpeta
            this.$store.commit('cupones/cupones/set_id_carpeta',carpeta.id)

            try {
                const {data} = await Cupones.listarCupones(this.idCarpetaActiva)
                this.cuponesVigentes = data.vigentes
                this.cuponesVencidos = data.vencidos
            } catch (e){
                this.error_catch(e);
            }

        },
        crearCarpeta(crear=true){
            if(crear){
                this.$refs.modalCrearCarpeta.toggle();
                return
            }
            this.$refs.modalCrearCarpeta.editar(this.carpetaSeleccionada);
        },
        updateCarpeta(obj){
            this.carpetaSeleccionada.nombre = obj.nombre
        },
        async eliminarCarpeta(){
            try {
                await Cupones.eliminarCarpeta(this.idCarpetaActiva)
                this.notificacion('','Carpeta eliminada correctamente','success')
                this.listarCarpetas()
            } catch (e){
                this.error_catch(e)
            }
        },
        async cambiarEstadoCarpeta(){
            try {
                await Cupones.cambioEstadoCarpeta(this.idCarpetaActiva)
                this.notificacion('','El estado se la carpeta ha sido actualizado','success')
            } catch (e){
                this.error_catch(e)
            }
        },
        funTipoCupon(tipo){
            if(tipo === 1){
                return "Aplicar Ya"
            }
            if(tipo === 2){
                return "Bono Fúturo"
            }
        },
        irDetalle(row){
            //console.log(row);
            if(row.tipo === 1){
                this.$router.push({name:'admin.cupones.ver.informacion1',params:{id_cupon:row.id}})
            }
            if(row.tipo === 2){
                this.$router.push({name:'admin.cupones.ver.informacion2',params:{id_cupon:row.id}})
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.select-cupon{
    height: calc(100vh - 180px);
    border-radius: 12px 12px 0px 0px;
}
.cont-cupon{
    background-color: #FFFFFF;
    border-radius: 12px 12px 0px 0px;
}
</style>
